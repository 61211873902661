// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terminology-js": () => import("./../../../src/pages/terminology.js" /* webpackChunkName: "component---src-pages-terminology-js" */),
  "component---src-templates-job-detail-js": () => import("./../../../src/templates/job-detail.js" /* webpackChunkName: "component---src-templates-job-detail-js" */),
  "component---src-templates-place-detail-js": () => import("./../../../src/templates/place-detail.js" /* webpackChunkName: "component---src-templates-place-detail-js" */)
}

